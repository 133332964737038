import SiteHeaderComponent from '@/components/core/SiteHeaderComponent.vue';
import { mapState } from 'vuex';
import { DateTime } from 'luxon';
export default {
  components: {
    SiteHeaderComponent
  },
  data: () => ({
    showOnlyUnreadNotifications: false,
    detailedNotificationDialog: false,
    target: 0,
    scrollOptions: {
      duration: 300,
      offset: 0,
      easing: 'easeInOutCubic'
    },
    search: '',
    headers: [{
      text: 'Status',
      value: 'read'
    }, {
      text: 'Betreff',
      value: 'subject'
    }, {
      text: 'Von',
      value: 'fromUser',
      align: 'start'
    }, {
      text: 'Nachricht',
      value: 'notificationText',
      align: 'start'
    }, {
      text: 'Gesendet',
      value: 'sendDate',
      align: 'end'
    }, {
      text: 'Aktionen',
      value: 'actions',
      sortable: false,
      align: 'end'
    }],
    selectedNotification: null
  }),
  methods: {
    openNotification(notification) {
      console.log(notification);
      this.detailedNotificationDialog = true;
      this.selectedNotification = notification;
      if (!notification.read) this.changeReadStatus(notification.nid);
    },
    exitDetailedNotification() {
      this.detailedNotificationDialog = false;
      this.selectedNotification = null;
    },
    changeReadStatus(nid) {
      this.$store.dispatch('CHANGE_READ_STATUS_NOTIFICATION', nid);
    },
    formatSendDate(sendDate) {
      return DateTime.fromISO(sendDate).toRelative();
    }
  },
  computed: {
    ...mapState({
      notifications: state => state.notifications.notifications
    })
  },
  mounted() {
    this.$store.dispatch('GET_ALL_NOTIFICATIONS');
  }
};